@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Signika+Negative:wght@300..700&display=swap');


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Montserrat, Arial, Helvetica, serif;
}

  .home,
  .printing,
  .consulting,
  .projects,
  .join-us {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
      font-family: Montserrat, Arial, Helvetica, serif;
    background-attachment: unset;
  }

  @media only screen and (max-width: 600px) {
    .home,
    .printing,
    .consulting,
    .projects,
    .join-us {
        height: 80vh;
        background-attachment: unset;
        background-size: cover;
    }
  }

/* 
.about {
    background-image: url('../public/images/multiple_printers.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 80px;
    position: relative;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
    background-attachment: fixed;
}
*/


.printing {
    background-image: url('/public/images/mini_cairns.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-family: Montserrat, Arial, Helvetica, serif;
    font-size: 7vw;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
}

.consulting {
    background-image: url('/images/projects22.png');
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    font-family: Montserrat, Arial, Helvetica, serif;
    font-size: 7vw;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
}

.projects {
    background: url('/public/images/multiple_printers.jpg') center center/cover no-repeat;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-family: Montserrat, Arial, Helvetica, serif;
    font-size: 7vw;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
}

.join-us {
    background-image: url('/public/images/openhouse2223.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-family: Montserrat, Arial, Helvetica, serif;
    font-size: 7vw;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .consulting {
    background-size: 100%;
  }
}
