@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Signika+Negative:wght@300..700&display=swap');

.footer-container {
    background-color: #F8F8F8;
    padding: 1rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: black solid 1px;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 12px;
    padding: 5px;
    color: rgb(80, 80, 80);
  }
  
  .footer-subscription > p {
    font-family: Montserrat, Arial, Helvetica, serif;
    font-weight: bold;
  }
  
  .footer-subscription-heading {
    margin-bottom: 14px;
    font-size: 20px;
  }
  
  .footer-subscription-text {
    margin-bottom: 8px;
    font-size: 14px;
  }
  
  .footer-input {
    padding: 0px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 0px;
    border: 1px solid #000;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
    align-items: center;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    align-items: center;
  }
  
  .footer-link-items h3 {
    margin-bottom: 0px;
  }
  
  .footer-link-items > h3 {
    color: rgb(40, 40, 40);
    text-align: center;
  }
  
  .footer-link-items a {
    color: rgb(40, 40, 40);
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #AA3030;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 0rem;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: rgb(40, 40, 40);
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
    padding: 0px;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 10px auto 0 auto;
    flex-wrap: wrap;
    padding: 10px;
    position: relative;
  }

  .footer-logo {
    z-index: 1;
    display: flex;
    padding: 4px;
    width: 130px;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .website-rights {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);  /* This centers it horizontally */
    color: rgb(40, 40, 40);
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }

    .social-media-wrap {
      flex-direction: column;
      align-items: center;
    }
  
    .social-icons {
      margin-top: 10px;
      justify-content: center;
      width: auto; 
    }

    .social-icons a {
      margin: 0 20px;  /* Increased margin for more space between the icons */
    }
  
    .footer-logo {
      margin-bottom: 10px;
    }
  
    .website-rights {
      margin-top: 10px;
      position: relative;
      width: 100%;
    }
  }
  
  @media screen and (max-width: 768px) {
  }