.cards {
  padding: 4rem;
  background: #fff;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 5px;
  transition: all 0.3s ease;
  scale: 98%;
  justify-content: center;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
}

.cards__item:hover {
  scale: 101%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.4);
}

.cards__item__link {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 5px;
  text-decoration: none;
  overflow: hidden;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 90%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  left: 10px;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: fill;
  object-position: center;
  transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 15px;
  color: #746E6E;
  text-align: left;
  font-size: 14px;
}

.cards__item__text {
  color: black;
  font-size: 18px;
  line-height: 20px;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  padding: 5px 0;
}

/* Responsive Design */
@media (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}