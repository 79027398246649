:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 3px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: Montserrat, Arial, Helvetica, serif;
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 10px;
  font-size: 20px;
}

.btn--large {
  padding: 16px 200px; /* Keep the button dimensions */
  font-size: 30px; /* Text size */
  font-weight: bold; /* Make text bold */
  font-stretch: expanded; /* Widen the font (if supported by the font family) */
}

/* Use this button for any default red button */
.btn--primary {
  margin: 10px;
  color: #BF3B3B;
  background-color: white;
  max-width: 150px;
  align-items: center;
  margin: auto;
  display: flex;
  justify-content: center;
  border: 3px solid var(--primary);
  border-color: #BF3B3B;
  transition: all 0.3s ease-in-out;
}

.btn--primary:hover {
  transition: all 0.3s ease-out;
  background-color: #BF3B3B;
  color: white;
  transform: scale(1.05); /* Scale up on hover */
}

.btn--outline:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
}