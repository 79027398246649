@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Signika+Negative:wght@300..700&display=swap');


.projects_wrapper > h3 {
  font-weight: bold;
  font-size: 24px;
  display: inline-block;
  padding: 5px 0px 5px;
    font-family: Montserrat, Arial, Helvetica, serif;
}

.projects_wrapper > p {
  box-sizing: border-box;
  font-weight: lighter;
  margin-bottom: 20px;
  line-height: 20px;
    padding: 8px 0px;
    font-family: Signika Negative, Arial, Helvetica, serif;
    font-size: 18px;
}

.projects_wrapper > ul {
  margin-left: 20px;
  margin-bottom: 20px;
}

.projects_wrapper {
    padding: 5px;
    margin: 30px 70px 30px 70px;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    font-family: Arial, Helvetica, sans-serif;
  }

img {
    position: relative;
    max-width: 500px;
    height: auto;
    margin-bottom: 5px;
    object-fit:contain;
  }

  table {
    text-align: center;
    margin-left: 20px;
    margin-bottom: 20px;
    font-weight: lighter;
    padding-top: 10px;
    padding-bottom: 10px;
    border-spacing: 40px 10px;
  }

  .consulting_img_wrapper.project{
    position: relative;
    display: flex;
    justify-content: start; 
    align-items: center;
    gap: 30px;
    margin: 10px auto;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding: 25px;
    max-width: 90%;
    scroll-snap-type: x mandatory;
  }